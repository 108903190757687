<template>
  <div class="row mx-0 card_item" :class="{disabled: disabled, 'h-100': !isSmall}">
    <div class="disabledBlock" v-if="disabled"></div>
    <div class="col-12 h-100" :class="[padding, {'p-4': !padding}]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPlain',
  props: ['disabled', 'padding', 'isSmall']
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.08);
  /* box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px; */
  color: var(--normal-color);
}
.card_item.disabled {
  opacity: 0.5;
  position: relative;
}
.disabledBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
